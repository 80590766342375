<template>
  <div id="content-main-div" >
    <p id="content-title-span">克拉玛依凯孚石油技术有限公司</p>
    <span id="content-sub-title-span">专业高效，值得信赖</span>
    <!-- <span id="content-sub-title-span">克拉玛依凯孚石油技术有限公司成立于2013年，是一家专门从事开采专业及辅助性活动为主的企业。</span> -->
    <!-- <div id="content-text-div">
      
    </div> -->
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {

    }
  },
}
</script>

<style>
#content-main-div{
  width: 100%;
  height: 800px;
  background-image: url('~/public/images/scroll_big_2.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}
#content-text-div{
  background-color: cyan
}
#content-title-span{
  margin: 15px 20px 15px 20px;
  font-weight: bold;
  font-size: 50px;
  color: white;
  opacity: 0.9;
}
#content-sub-title-span{
  margin: 0px 20px 15px 20px;
  font-weight: bold;
  font-size: 25px;
  color: white;
  opacity: 0.9;
}
</style>
