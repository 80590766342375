<template>
  <div id="home-main-div">
    <Content/>
    <Banner/>
    <Intro/>
  </div>
</template>

<script>
import Banner from './banner/_banner.vue'
import Content from './content/_content.vue'
import Intro from './content/_intro.vue'
export default {
  name: 'HomePage',
  components: {
    Banner,
    Content,
    Intro
  }
}
</script>

<style>
#home-main-div{

}
</style>
