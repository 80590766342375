<template>
  <div id="banner-main-div">
    <div id="banner-title-div">
      <p id="banner-title-p">主营业务</p>
    </div>
    <el-carousel type="card" height="300px;">
      <el-carousel-item v-for="obj in items" :key="obj">
        <div id="img-div">
          <span id="img-div-title">{{obj.title}}</span>
          <el-image style="width: 450px; height: 280px" :src="obj.url"/>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      items: [
        {
          url: 'images/scroll_new_mwd.webp',
          title: '随钻测量MWD'
        },
        {
          url: 'images/scroll_new_lwd.jpeg',
          title: '随钻测井LWD'
        },
        {
          url: 'images/scroll_new_rss.webp',
          title: '旋转导向RSS'
        },
        {
          url: 'images/scroll_new_broke.jpg',
          title: '压裂工程'
        },
        {
          url: 'images/scroll_new_pdc.jpg',
          title: '高效PDC钻头'
        },
        {
          url: 'images/scroll_new_manage.png',
          title: '定制化解决方案'
        },
      ]
    }
  },
}
</script>

<style>
#banner-main-div{

}
#banner-title-div{
  margin: 0px;
  display: flex;
  justify-content: center;
}
#banner-title-p{
  font-weight: bold;
  font-size: 30px;
  margin: 15px;
  opacity: 0.6;
}
#img-div{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#img-div-title{
  font-weight: bold;
  font-size: 20px;
  opacity: 0.6;
}
</style>
