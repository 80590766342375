<template>
  <div id="intro-main-div" >
    <span id="intro-sub-title-span">克拉玛依凯孚石油技术有限公司，成立于2013年，位于新疆维吾尔自治区克拉玛依市，是集高端石油技术的研发、生产、销售于一体的高新技术企业</span>
    <span id="intro-sub-title-span">公司主营业务包括：随钻测井MWD、随钻测量LWD、旋转导向RSS、压裂工具、高效PDC钻头、钻井定制化解决方案等</span>
    <span id="intro-sub-title-span">邮箱：972414347@qq.com</span>
    <span id="intro-sub-title-span">地址：新疆克拉玛依市克拉玛依区友谊路169号905室</span>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {

    }
  },
}
</script>

<style>
#intro-main-div{
  width: 100%;
  height: 350px;
  background-image: url('~/public/images/bottom_big.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#intro-text-div{

}
#intro-sub-title-span{
  margin: 0px 20px 15px 20px;
  font-weight: bold;
  font-size: 20px;
  color: white;
  opacity: 0.9;
}
</style>
