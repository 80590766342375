<template>
  <div id="app">
    <div id="wrapper">
      <!-- <Header/> -->
      <div id="content-wrapper">
        <HomePage/>
      </div>
    </div>
  </div>
</template>

<script>
// import Header from './components/header/_header.vue'
import HomePage from './components/HomePage.vue'

export default {
  name: 'App',
  components: {
    // Header,
    HomePage
  }
}
</script>

<style>
head{
  padding: 0px;
  margin: 0px;
}
body{
  padding: 0px;
  margin: 0px;
}
#wrapper {
  position: static;
  overflow: visible;
}
#content-wrapper{
  position: absolute;
  top:0px;
  width: 100%;
  margin: 0px;
  height: 100%;
  overflow-y: scroll;
}
</style>
